"use client";

import { Spinner } from "@/components/primitives/spinner";

export const Loader = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-6">
      <Spinner />
    </div>
  );
};
