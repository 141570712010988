"use client";

import { Spinner as ZapierSpinner } from "@zapier/design-system";
import { forwardRef } from "react";

import { useTheme } from "@/hooks/use-theme";

interface SpinnerProps {
  ariaLabel?: string;
  theme?: "light" | "dark";
  size?: "small" | "medium" | "large";
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (props, ref) => {
    const { theme: appTheme } = useTheme();

    return (
      <ZapierSpinner
        color={props.theme ?? (appTheme === "dark" ? "light" : "dark")}
        {...props}
      />
    );
  },
);

Spinner.displayName = "Spinner";
